import { ThemeContextProvider, Themes } from 'jcm-design-system';
import { StrictMode, Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';

// import { kcContext as kcAccountThemeContext } from './account/kcContext';
import { kcContext as kcLoginThemeContext } from './login/kcContext';

const KcLoginThemeApp = lazy(() => import('./login/KcApp'));
// const KcAccountThemeApp = lazy(() => import('./account/KcApp'));

const root = createRoot(document.getElementById('root') as HTMLElement);

const lightTheme = Themes.getLightTheme();

root.render(
	<StrictMode>
		<ThemeContextProvider theme={lightTheme}>
			<Suspense>
				{(() => {
					if (kcLoginThemeContext !== undefined) {
						return <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
					}

					// if (kcAccountThemeContext !== undefined) {
					// 	return <KcAccountThemeApp kcContext={kcAccountThemeContext} />;
					// }

					throw new Error('This app is a Keycloak theme' + "It isn't meant to be deployed outside of Keycloak");
				})()}
			</Suspense>
		</ThemeContextProvider>
	</StrictMode>
);
